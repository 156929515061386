/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable prefer-const */
/* eslint-disable no-self-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, DateTime, Tooltip, DataLabel, Selection, Export, LineSeries, SmaIndicator, Crosshair } from '@syncfusion/ej2-react-charts';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { RiFileImageLine, RiFilePdf2Fill } from 'react-icons/ri';
import { Methods } from '../pages/Methods';
import { useStateContext } from '../contexts/ContextProvider';
import '../pages/Zackat.css';

export default function Heartbeat() {
  let chartInstance;
  let intervalId;
  let setTimeoutValue = 1000;
  const charts = JSON.parse(localStorage.getItem('charts'));
  const {
    currentColor,
  } = useStateContext();
  const primaryxAxis = { valueType: 'DateTime', labelIntersectAction: 'Rotate45' };
  const tooltip = {
    enable: true,
    fill: '#F6821E',
    border: {
      width: 2,
      color: '#366976',
    },
  };
  const titlestyle = {
    color: '#366976',
  };
  /*
  function chartTemplate(args) {
    return (
      <div id="templateWrap" style={{ border: '1px solid black', backgroundColor: 'red', padding: '3px 3px 3px 3px' }}>
        <div>{args.point.x.toLocaleDateString()}</div>
        <div>{args.point.y}</div>
      </div>
    );
  }
  */
  // const template = chartTemplate;
  const [heatindex, setHeatIndex] = useState(false);
  const [hydration, setHydration] = useState(false);
  const [oxygen, setOxygen] = useState(false);
  const [hrv, setHRV] = useState(false);
  const [bpm, setBPM] = useState(false);
  const [act, setActivity] = useState(false);
  const [sys, setSystolic] = useState(false);
  const [dia, setDiastolic] = useState(false);

  function selectChart(e) {
    if (!e || !e.value) {
      return;
    }
    if (e.value !== charts) {
      localStorage.setItem('charts', JSON.stringify(e.value));
    }
    if (e.value.includes('Hydration')) {
      setHydration(true);
    } else {
      chartInstance.series.forEach((plot) => {
        if (plot.id === 'Hydration') {
          chartInstance.removeSeries(plot.index);
        }
      });
      setHydration(false);
    }
    if (e.value.includes('Oxygen')) {
      setOxygen(true);
    } else {
      chartInstance.series.forEach((plot) => {
        if (plot.id === 'Oxygen') {
          chartInstance.removeSeries(plot.index);
        }
      });
      setOxygen(false);
    }
    if (e.value.includes('HRV')) {
      setHRV(true);
    } else {
      chartInstance.series.forEach((plot) => {
        if (plot.id === 'HRV') {
          chartInstance.removeSeries(plot.index);
        }
      });
      setHRV(false);
    }
    if (e.value.includes('HeatIndex')) {
      setHeatIndex(true);
    } else {
      chartInstance.series.forEach((plot) => {
        if (plot.id === 'HeatIndex') {
          chartInstance.removeSeries(plot.index);
        }
      });
      setHeatIndex(false);
    }
    if (e.value.includes('HRV')) {
      setHRV(true);
    } else {
      chartInstance.series.forEach((plot) => {
        if (plot.id === 'HRV') {
          chartInstance.removeSeries(plot.index);
        }
      });
      setHRV(false);
    }
    if (e.value.includes('HeartRate')) {
      setBPM(true);
    } else {
      chartInstance.series.forEach((plot) => {
        if (plot.id === 'BPM') {
          chartInstance.removeSeries(plot.index);
        }
      });
      setBPM(false);
    }
    if (e.value.includes('Activity')) {
      setActivity(true);
    } else {
      chartInstance.series.forEach((plot) => {
        if (plot.id === 'Activity') {
          chartInstance.removeSeries(plot.index);
        }
      });
      setActivity(false);
    }
    if (e.value.includes('Bloodpressure')) {
      setSystolic(true);
      setDiastolic(true);
    } else {
      chartInstance.series.forEach((plot) => {
        if (plot.id === 'Systolic') {
          chartInstance.removeSeries(plot.index);
        }
      });
      setSystolic(false);
      chartInstance.series.forEach((plot) => {
        if (plot.id === 'Diastolic') {
          chartInstance.removeSeries(plot.index);
        }
      });
      setDiastolic(false);
    }
  }
  const temp = {
    visible: true,
    height: 10,
    width: 10,
    shape: 'Diamond',
    color: 'blue',
    dataLabel: { visible: true, format: '{value}°F' },
  };
  const hi = {
    visible: true,
    height: 10,
    width: 10,
    shape: 'Circle',
    color: 'red',
    dataLabel: { visible: true, format: '{value}°F' },
  };
  const vari = {
    visible: true,
    height: 10,
    width: 10,
    shape: 'Circle',
    color: 'darkgray',
    dataLabel: { visible: true, format: '{value}ms' },
  };
  const hyd = {
    visible: true,
    height: 10,
    width: 10,
    shape: 'Circle',
    color: 'blue',
    dataLabel: { visible: true, format: '{value}%' },
  };
  const oxy = {
    visible: true,
    height: 10,
    width: 10,
    shape: 'Circle',
    color: 'lightblue',
    dataLabel: { visible: true, format: '{value}%' },
  };
  const hr = {
    visible: true,
    height: 10,
    width: 10,
    shape: 'Circle',
    color: 'darkgreen',
    dataLabel: { visible: true, format: '{value}bpm' },
  };
  const step = {
    visible: true,
    height: 10,
    width: 10,
    shape: 'Circle',
    color: 'purple',
    dataLabel: { visible: true, format: '{value}' },
  };
  const systolic = {
    visible: true,
    height: 10,
    width: 10,
    shape: 'Circle',
    color: 'cyan',
    dataLabel: { visible: true, format: '{value}' },
  };
  const diastolic = {
    visible: true,
    height: 10,
    width: 10,
    shape: 'Circle',
    color: 'orange',
    dataLabel: { visible: true, format: '{value}' },
  };
  const body = {
    visible: true,
    height: 10,
    width: 10,
    shape: 'Triangle',
    color: 'orange',
    border: {
      width: 2,
      color: 'orange',
    },
    dataLabel: { visible: true, format: '{value}°F' },
  };
  // const { REACT_APP_OPENWEATHER_LICENSE_KEY } = process.env;
  const { REACT_APP_API_ENDPOINT } = process.env;
  const { REACT_APP_WEATHERAPI_LICENSE_KY } = process.env;
  const [apiResponse, setApiResponse] = useState([]);
  const [eventResponse, setEventResponse] = useState([]);
  const [hriEventResponse, setHRIEventResponse] = useState([]);
  const [oxyEventResponse, setOxyEventResponse] = useState([]);
  const [hydEventResponse, setHyEventResponse] = useState([]);
  const [bpmEventResponse, setBpmEventResponse] = useState([]);
  const [actEventResponse, setActEventResponse] = useState([]);
  const [sysEventResponse, setSysEventResponse] = useState([]);
  const [diaEventResponse, setDiaEventResponse] = useState([]);
  const usr = JSON.parse(localStorage.getItem('user'));

  const [apiLoading, setApiLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const associateid = searchParams.get('id');
  const multiselectDatasource = [
    { value: 'Hydration', text: 'Hydration' },
    { value: 'HeatIndex', text: 'HeatIndex' },
    { value: 'Oxygen', text: 'Oxygen' },
    { value: 'HeartRate', text: 'HeartRate' },
    { value: 'HRV', text: 'HRV' },
    { value: 'Bloodpressure', text: 'Bloodpressure' },
    { value: 'Activity', text: 'Activity' },
  ];
  const fields = { value: 'value', text: 'text' };

  let cDate = new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });

  const { loading, data } = Methods('associates', 'GET', associateid);
  const getData = async (args) => {
    const loc = window.location;
    cDate = new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const weatherApiKey = `${REACT_APP_WEATHERAPI_LICENSE_KY}`;
    const api = `https://api.weatherapi.com/v1/history.json?q=${data.associates[0].latlng.latitude},${data.associates[0].latlng.longitude}&dt=${cDate}&hour_fields=temp_c,wind_mph&aqi=yes&key=${weatherApiKey}`;
    if (apiLoading) {
      await axios.get(`${api}`)
        .then((response) => {
          if (response && response.data) {
            setApiResponse(response.data.forecast.forecastday[0].hour);
          }
        })
        .catch((error) => console.log(error));
    }
    await axios({
      url: `${REACT_APP_API_ENDPOINT}/events?associateid=${associateid}`,
      method: 'get',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => {
        if (resp && resp.data) {
          const events = [];
          const hydrationevents = [];
          const oxygenevents = [];
          const hrvevents = [];
          const bpmevents = [];
          const actevents = [];
          const sysevents = [];
          const diaevents = [];
          resp.data.forEach((element) => {
            if (element.vitals && element.vitals.type && element.vitals.type === 'core' && element.vitals.timestamp) {
              let d = format(parseISO(element.timestamp), 'yyyy-MM-dd HH:mm');
              let cmp = `${cDate} 00:00`;
              if (d >= cmp) {
                events.push({ core: element.vitals.reading, time: `${format(parseISO(element.vitals.timestamp), 'yyyy-MM-dd HH:mm')}` });
              }
            }
            if (element.vitals && element.vitals.type && element.vitals.type === 'hrv' && element.vitals.timestamp) {
              let d = format(parseISO(element.timestamp), 'yyyy-MM-dd HH:mm');
              let cmp = `${cDate} 00:00`;
              if (d >= cmp) {
                hrvevents.push({ hrv: element.vitals.reading, time: `${format(parseISO(element.vitals.timestamp), 'yyyy-MM-dd HH:mm')}` });
              }
            }
            if (element.vitals && element.vitals.type && element.vitals.type === 'oxygen' && element.vitals.timestamp) {
              let d = format(parseISO(element.timestamp), 'yyyy-MM-dd HH:mm');
              let cmp = `${cDate} 00:00`;
              if (d >= cmp) {
                oxygenevents.push({ oxygen: element.vitals.reading, time: `${format(parseISO(element.vitals.timestamp), 'yyyy-MM-dd HH:mm')}` });
              }
            }
            if (element.vitals && element.vitals.type && element.vitals.type === 'hydration' && element.vitals.timestamp) {
              let d = format(parseISO(element.timestamp), 'yyyy-MM-dd HH:mm');
              let cmp = `${cDate} 00:00`;
              if (d >= cmp) {
                hydrationevents.push({ hydration: element.vitals.reading, time: `${format(parseISO(element.vitals.timestamp), 'yyyy-MM-dd HH:mm')}` });
              }
            }
            if (element.vitals && element.vitals.type && element.vitals.type === 'bpm' && element.vitals.timestamp) {
              let d = format(parseISO(element.timestamp), 'yyyy-MM-dd HH:mm');
              let cmp = `${cDate} 00:00`;
              if (d >= cmp) {
                bpmevents.push({ bpm: element.vitals.reading, time: `${format(parseISO(element.vitals.timestamp), 'yyyy-MM-dd HH:mm')}` });
              }
            }
            if (element.vitals && element.vitals.type && element.vitals.type === 'steps' && element.vitals.timestamp) {
              let d = format(parseISO(element.timestamp), 'yyyy-MM-dd HH:mm');
              let cmp = `${cDate} 00:00`;
              if (d >= cmp) {
                actevents.push({ activity: element.vitals.reading, time: `${format(parseISO(element.vitals.timestamp), 'yyyy-MM-dd HH:mm')}` });
              }
            }
            if (element.vitals && element.vitals.type && element.vitals.type === 'bp' && element.vitals.timestamp) {
              let d = format(parseISO(element.timestamp), 'yyyy-MM-dd HH:mm');
              let cmp = `${cDate} 00:00`;
              if (d >= cmp) {
                const sysdia = element.vitals.reading.split('/');
                sysevents.push({ sys: sysdia[0], time: `${format(parseISO(element.vitals.timestamp), 'yyyy-MM-dd HH:mm')}` });
                diaevents.push({ dia: sysdia[1], time: `${format(parseISO(element.vitals.timestamp), 'yyyy-MM-dd HH:mm')}` });
              }
            }
          });
          setEventResponse(events);
          setHRIEventResponse(hrvevents);
          setOxyEventResponse(oxygenevents);
          setHyEventResponse(hydrationevents);
          setBpmEventResponse(bpmevents);
          setActEventResponse(actevents);
          setSysEventResponse(sysevents);
          setDiaEventResponse(diaevents);
          if (args && args.chart && args.chart.series && args.chart.series.length > 0) {
            args.chart.series[0].dataSource = events;
          }
          if (hydration && chartInstance && chartInstance.series) {
            chartInstance.series.forEach((plot) => {
              if (plot.id === 'Hydration') {
                args.chart.series[plot.index].dataSource = hydrationevents;
              }
            });
          }
          if (oxygen && chartInstance && chartInstance.series) {
            chartInstance.series.forEach((plot) => {
              if (plot.id === 'Oxygen') {
                args.chart.series[plot.index].dataSource = oxygenevents;
              }
            });
          }
          if (hrv && chartInstance && chartInstance.series) {
            chartInstance.series.forEach((plot) => {
              if (plot.id === 'HRV') {
                args.chart.series[plot.index].dataSource = hrvevents;
              }
            });
          }
          if (bpm && chartInstance && chartInstance.series) {
            chartInstance.series.forEach((plot) => {
              if (plot.id === 'BPM') {
                args.chart.series[plot.index].dataSource = hrvevents;
              }
            });
          }
          if (act && chartInstance && chartInstance.series) {
            chartInstance.series.forEach((plot) => {
              if (plot.id === 'Activity') {
                args.chart.series[plot.index].dataSource = actevents;
              }
            });
          }
          if (sys && chartInstance && chartInstance.series) {
            chartInstance.series.forEach((plot) => {
              if (plot.id === 'Systolic') {
                args.chart.series[plot.index].dataSource = sysevents;
              }
            });
          }
          if (dia && chartInstance && chartInstance.series) {
            chartInstance.series.forEach((plot) => {
              if (plot.id === 'Diastolic') {
                args.chart.series[plot.index].dataSource = diaevents;
              }
            });
          }
        }
        if (charts && args && args.chart && args.chart.series && charts.length !== args.chart.series.length && loc.pathname === '/basic') {
          intervalId = setTimeout(getData, 1000);
        } else if (loc.pathname === '/basic') {
          intervalId = setTimeout(getData, 30000);
        }
      })
      .catch((error) => console.log(error));
    setApiLoading(false);
  };
  function clickHandler() {
    chartInstance.exportModule.export('PNG', `${data.associates[0].name}_${cDate}_CoreBodyTemperature`);
  }
  function clickHandlerPDF() {
    const header = {
      content: 'Chart Header',
      fontSize: 15,
    };

    const footer = {
      content: 'Chart Footer',
      fontSize: 15,
    };
    chartInstance.exportModule.export('PDF', `${data.associates[0].name}_${cDate}_CoreBodyTemperature`, 1, [chartInstance], null, null, true, header, footer);
  }
  function loaded(args) {
    intervalId = setTimeout(() => {
      if (chartInstance === null) {
        clearTimeout(intervalId);
        return;
      }
      selectChart({ value: charts });
      getData(args);
      if (apiResponse) {
        if (heatindex) {
          chartInstance.series.forEach((plot) => {
            if (plot.id === 'HeatIndex') {
              args.chart.series[plot.index].dataSource = apiResponse;
            }
          });
        }
        clearTimeout(intervalId);
        setTimeoutValue = 1000;
      }
    }, setTimeoutValue);
  }
  if (loading) {
    return <p>External Data Loading...</p>;
  }
  return (
    <div>
      <button
        value="print"
        onClick={clickHandler.bind(this)}
        type="button"
        className="text-xl p-2 font-semibold text-gray-400 e-custom-btn"
        style={{ backgroundColor: currentColor, borderRadius: '20%' }}
      >
        <RiFileImageLine />
      </button>
      <button
        value="print"
        onClick={clickHandlerPDF.bind(this)}
        type="button"
        className="text-xl p-2 font-semibold text-gray-400 e-custom-btn"
        style={{ backgroundColor: '#366976', borderRadius: '20%' }}
      >
        <RiFilePdf2Fill />
      </button>
      <ChartComponent id="charts" loaded={loaded.bind(this)} ref={(chart) => { chartInstance = chart; }} primaryXAxis={primaryxAxis} tooltip={tooltip} titleStyle={titlestyle} isMultiSelect selectionMode="Point" title="Monaco Charts">
        <Inject services={[LineSeries, Legend, Tooltip, DataLabel, Export, DateTime, SmaIndicator, Crosshair, Selection]} />
        <SeriesCollectionDirective>
          <SeriesDirective id="Core" dataSource={eventResponse} xName="time" yName="core" name="Core Body" marker={body} fill={currentColor} type="Line" />
          <SeriesDirective id="Temp" dataSource={apiResponse} xName="time" yName="temp_f" name="Temperature" marker={temp} fill="black" type="Line" />
          {heatindex && <SeriesDirective id="HeatIndex" dataSource={apiResponse} xName="time" yName="heatindex_f" name="HeatIndex" marker={hi} fill="red" type="Line" />}
          {hydration && <SeriesDirective id="Hydration" dataSource={hydEventResponse} xName="time" yName="hydration" name="Hydration" marker={hyd} fill="blue" type="Line" /> }
          {oxygen && <SeriesDirective id="Oxygen" dataSource={oxyEventResponse} xName="time" yName="oxygen" name="Oxygen" marker={oxy} fill="lightblue" type="Line" /> }
          {hrv && <SeriesDirective id="HRV" dataSource={hriEventResponse} xName="time" yName="hrv" name="HRV" marker={vari} fill="darkgray" type="Line" /> }
          {bpm && <SeriesDirective id="BPM" dataSource={bpmEventResponse} xName="time" yName="bpm" name="Heartrate" marker={hr} fill="darkgreen" type="Line" />}
          {act && <SeriesDirective id="Activity" dataSource={actEventResponse} xName="time" yName="act" name="Activity" marker={step} fill="purple" type="Line" />}
          {sys && <SeriesDirective id="Systolic" dataSource={sysEventResponse} xName="time" yName="sys" name="Systolic" marker={systolic} fill="cyan" type="Line" />}
          {dia && <SeriesDirective id="Diastolic" dataSource={diaEventResponse} xName="time" yName="dia" name="Diastolic" marker={diastolic} fill="orange" type="Line" />}
        </SeriesCollectionDirective>
      </ChartComponent>
      <div>
        Select Charts:<MultiSelectComponent id="chartSelect" dataSource={multiselectDatasource} fields={fields} onChange={selectChart} value={charts} placeholder="Additional Charts" />
      </div>
    </div>
  );
}
