/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react';
import { APIProvider, Map, InfoWindow, AdvancedMarker, Pin, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import zackatPin from '../data/mappin.png';
import './Map.css';

const GMap = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refreshData, setRefreshData] = useState(true);
  const [searchParams] = useSearchParams();
  const associateid = searchParams.get('id');
  const centerlat = Number(searchParams.get('lat'));
  const centerlng = Number(searchParams.get('lng'));
  const [markers, setMarker] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
  const { REACT_APP_GOOGLE_MAPS_ID } = process.env;
  const usr = JSON.parse(localStorage.getItem('user'));

  let initialMarkers = [];
  const getData = async () => {
    // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/events?associateid=${associateid}`);
    const response = await axios({
      url: `${REACT_APP_API_ENDPOINT}/events?associateid=${associateid}`,
      method: 'get',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
        'Content-Type': 'application/json',
      },
    });
    setData(response.data);
    setLoadingData(false);
    initialMarkers = [];
    if (response.data) {
      response.data.map((event) => {
        if (event && event.device && event.latlng && event.latlng.latitude && event.latlng.longitude && event.latlng.latitude !== 0 && event.latlng.longitude !== 0) {
          const marker = {
            lat: event.latlng.latitude,
            lng: event.latlng.longitude,
            title: event.device,
            _id: event._id,
            info: `Timestamp: ${format(parseISO(event.timestamp), 'MM/dd/yy hh:mm')}`,
          };
          initialMarkers.push(marker);
        }
      });
    }
    setTimeout(getData, 30000);
    setMarker(initialMarkers);
  };
  useEffect(() => {
    getData();
  }, [refreshData]);
  if (loadingData && !refreshData) {
    return <p>Loading... </p>;
  }
  const [infoWindowShown, setInfoWindowShown] = useState(false);
  const [markerRef, amarker] = useAdvancedMarkerRef();

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    [],
  );
  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  return (
    <APIProvider apiKey={`${REACT_APP_GOOGLE_MAPS_API_KEY}`}>
      <button
        className="button"
        type="button"
        onClick={() => setRefreshData(!refreshData)}
      >
        Refresh
      </button>
      <Map
        style={{ width: '100%', height: '100vh' }}
        defaultCenter={{ lat: centerlat, lng: centerlng }}
        mapId={`${REACT_APP_GOOGLE_MAPS_ID}`}
        defaultZoom={12}
        gestureHandling="greedy"
        disableDefaultUI={false}
      >
        {/* <Marker position={{ lat: 33.049831, lng: -96.838485 }} /> */}
        {/* <AdvancedMarker places={markers} /> */}
        {markers.map((marker) => (
          <AdvancedMarker
            className="bounce"
            key={marker._id}
            ref={markerRef}
            onClick={handleMarkerClick}
            position={marker}
          >
            <img src={zackatPin} width="60px" height="60px" />
            {/*
            <Pin
              background="#000000"
              borderColor="#ffffff"
              glyphColor="#ff0000"
              title={marker.title}
            />
            */}
            {infoWindowShown && (
            <InfoWindow anchor={amarker} position={marker} onClose={handleClose}>
              <h2>Device: {marker.title}</h2>
              <p>{marker.info}</p>
            </InfoWindow>
            )}
          </AdvancedMarker>

        ))}
      </Map>
    </APIProvider>

  );
};
export default GMap;
