/* eslint-disable no-plusplus */
/* eslint-disable import/no-cycle */
import React from 'react';

import { GoDotFill } from 'react-icons/go';
import { Stacked, Button, SparkLine } from '../components';

import { Methods } from './Methods';
import { useStateContext } from '../contexts/ContextProvider';

const Dashboard = () => {
  const { loading, data } = Methods('dashboard', 'GET', '');
  const { currentColor, currentMode } = useStateContext();
  if (loading) {
    return <p>Loading... </p>;
  }
  let activeCount = 0;
  let stockCount = 0;
  let retiredCount = 0;
  const inventoryAreaData = [];
  for (let ix = 0; ix < data.length; ix++) {
    if (data[ix]._id.status === 'stock') {
      stockCount += data[ix].count;
    } else if (data[ix]._id.status === 'retired') {
      retiredCount += data[ix].count;
    } else if (data[ix]._id.status === 'active') {
      activeCount += data[ix].count;
    }
    inventoryAreaData.push({ x: ix, yval: data[ix].count });
  }
  const totalCount = activeCount + retiredCount + stockCount;

  return (
    <div className="flex gap-10 flex-wrap justify-center">
      <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780  ">
        <div className="flex justify-between">
          <p className="font-semibold text-xl">Inventory</p>
          <div className="flex items-center gap-4">
            <p className="flex items-center gap-2 text-gray-600 hover:drop-shadow-xl">
              <span>
                <GoDotFill />
              </span>
              <span>Total</span>
            </p>
            <p className="flex items-center gap-2 text-green-400 hover:drop-shadow-xl">
              <span>
                <GoDotFill />
              </span>
              <span>Active</span>
            </p>
          </div>
        </div>
        <div className="mt-10 flex gap-10 flex-wrap justify-center">
          <div className=" border-r-1 border-color m-4 pr-10">
            <div>
              <p>
                <span className="text-3xl font-semibold">{totalCount}</span>
                <span
                  className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs"
                >
                  23%
                </span>
              </p>
              <p className="text-gray-500 mt-1">Total</p>
            </div>
            <div className="mt-8">
              <p className="text-3xl font-semibold">{activeCount}</p>

              <p className="text-gray-500 mt-1">Active</p>
            </div>

            <div className="mt-5">
              <SparkLine
                currentColor={currentColor}
                id="line-sparkLine"
                type="Line"
                height="80px"
                width="250px"
                data={inventoryAreaData}
                color="red"
              />
            </div>
            <div className="mt-10">
              <Button
                color="white"
                bgColor={currentColor}
                text="Download Report ↵"
                borderRadius="10px"
              />
            </div>
          </div>
          <div>
            <Stacked currentMode={currentMode} width="320px" height="360px" />
          </div>
        </div>
      </div>
      <div>
        <div
          className=" rounded-2xl md:w-400 p-4 m-3"
          style={{ backgroundColor: currentColor }}
        >
          <div className="flex justify-between items-center ">
            <p className="font-semibold text-white text-2xl">Inventory</p>

            <div>
              <p className="text-2xl text-white font-semibold mt-8">{totalCount}</p>
              <p className="text-gray-200">Totals</p>
            </div>
          </div>

          <div className="mt-4">
            <SparkLine
              currentColor={currentColor}
              id="column-sparkLine"
              height="100px"
              type="Column"
              data={inventoryAreaData}
              width="320"
              color="rgb(242, 252, 253)"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
