/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Methods } from '../pages/Methods';
import './card.component.css';
import '../pages/Zackat.css';

const FlipOrig = () => {
  const usr = JSON.parse(localStorage.getItem('user'));
  const [searchParams] = useSearchParams();
  const associateid = searchParams.get('id');
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [email, setMyEmail] = useState('');
  const [mobile, setMyMobile] = useState('');
  const [address, setMyAddress] = useState('');
  const [address1, setMyAddress1] = useState('');
  const [city, setMyCity] = useState('');
  const [state, setMyState] = useState('');
  const [zip, setMyZip] = useState('');
  const [country, setMyCountry] = useState('');
  const [optinSms, setMyOptInSMS] = useState(false);
  const { loading, data } = Methods('associates', 'GET', associateid);
  if (loading) {
    return <p>Loading... </p>;
  }
  function saveAssociateProfile() {
    data.associates[0].email = email;
    data.associates[0].mobile = mobile;
    data.associates[0].address = address;
    data.associates[0].address1 = address1;
    data.associates[0].city = city;
    data.associates[0].state = state;
    data.associates[0].zip = zip;
    data.associates[0].country = country;
    data.associates[0].smsOptin = optinSms;
    const updateAssociate = async () => {
      const newData = data.associates[0];
      const config = {
        Authorization: `JWTToken ${usr.token}`,
      };

      axios.put(
        `${REACT_APP_API_ENDPOINT}/associates`,
        newData,
        config,
      ).then((response) => {
        // Handle the response
        console.log(response.data);
      })
        .catch((error) => {
          // Handle errors
        });
    };
    updateAssociate();
  }
  function formatPhoneNumber(phoneNumberString) {
    const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
  const handleChange = ({ target }) => {
    const val = target.value;
    if (target.id === 'email') {
      setMyEmail(val);
    } else if (target.id === 'mobile') {
      setMyMobile(val);
    } else if (target.id === 'address') {
      setMyAddress(val);
    } else if (target.id === 'address1') {
      setMyAddress1(val);
    } else if (target.id === 'city') {
      setMyCity(val);
    } else if (target.id === 'state') {
      setMyState(val);
    } else if (target.id === 'zip') {
      setMyZip(val);
    } else if (target.id === 'country') {
      setMyCountry(val);
    } else if (target.id === 'optinsms') {
      setMyOptInSMS(target.checked);
    }
  };
  /*
  import profileBg from '../data/gradientlong.png';
   backgroundImage: `url(${profileBg})`,
  */
  return (
    <div className="control-pane">
      <div className="control-section card-control-section flip_card_layout">
        <div className="e-card-resize-container">
          <div className="row">
            <div className="row card-layout">
              <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8">
                <div
                  className="zackat-div"
                  id="card_flip"
                  style={{
                    backgroundColor: '#F6821E',
                    color: 'white',
                  }}
                >
                  <div className="e-card-header e-front">
                    <div className="e-card-header-caption" style={{ float: 'right' }}>
                      <div className="e-card-header-title">&nbsp;&nbsp;Name: {data.associates[0].name}</div>
                      <div className="e-card-sub-title">&nbsp;&nbsp;Job Task: {data.associates[0].jobtask} </div>
                      </div>
                  </div>
                  <div className="e-card-actions e-front">
                    <table>
                      <tbody>
                        <tr>
                          <td>Email Address:</td>
                          <td>
                            <input
                              defaultValue={data.associates[0].email}
                              type="text"
                              id="email"
                              placeholder="email address"
                              style={{ backgroundColor: '#02071A' }}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Mobile:
                          </td>
                          <td>
                            <input
                              defaultValue={formatPhoneNumber(data.associates[0].mobile)}
                              type="text"
                              id="mobile"
                              placeholder="mobile number"
                              style={{ backgroundColor: '#02071A' }}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                  </tbody></table>
                  </div>

                    <div className="e-card-header e-back ">
                    <div className="e-card-header-caption">
                      <div className="e-card-header-title">-------</div>
                      <div className="e-card-sub-title">
                        <table>
                          <tbody>
                              <tr><td>Address</td>
                              <td>
                                <input
                                  defaultValue={data.associates[0].address}
                                  type="text"
                                  id="address"
                                  placeholder=" address"
                                  style={{ backgroundColor: '#02071A' }}
                                  onChange={handleChange}
                                /></td>
                            </tr>
                            <tr><td>Address cont.</td>
                              <td>
                                <input
                                  defaultValue={data.associates[0].address1}
                                  type="text"
                                  id="address1"
                                  placeholder="address cont."
                                  style={{ backgroundColor: '#02071A' }}
                                  onChange={handleChange}
                                />
                              </td>
                            </tr>
                          <tr><td>City:</td>
                              <td>
                              <input
                                defaultValue={data.associates[0].city}
                                type="text"
                                id="city"
                                placeholder="city"
                                style={{ backgroundColor: '#02071A' }}
                                onChange={handleChange}
                              />
                              </td>
                                <td>State:</td>
                                <td>
                                  <input
                                    defaultValue={data.associates[0].state}
                                    type="text"
                                    id="state"
                                    placeholder="state"
                                    style={{ backgroundColor: '#02071A' }}
                                    onChange={handleChange}
                                  /></td>
                              </tr>
                          <tr><td>Zip:</td>
                              <td>
                                <input
                                  defaultValue={data.associates[0].zip}
                                  type="text"
                                  id="zip"
                                  placeholder="postal code"
                                  style={{ backgroundColor: '#02071A' }}
                                  onChange={handleChange}
                                /></td>
                              <td>Country:</td>
                                <td>
                                  <input
                                    defaultValue={data.associates[0].country}
                                    type="text"
                                    id="country"
                                    placeholder="country"
                                    style={{ backgroundColor: '#02071A' }}
                                    onChange={handleChange}
                                  /></td>
                            </tr>
                            <tr><td>
                              Opt-In SMS&nbsp;&nbsp;<input
                                type="checkbox"
                                id="optinsms"
                                checked={data.associates[0].smsOptin}
                                onChange={handleChange}
                              />
                            </td></tr>
                          </tbody>
                        </table>
                        <button
                          type="button"
                          onClick={() => saveAssociateProfile()}
                          className="relative text-xl rounded-full p-3 hover:bg-light-gray"
                        >
                          <span
                            className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
                          />
                          Save
                        </button>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FlipOrig;
