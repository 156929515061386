/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { parseISO } from 'date-fns';
import { useNavigate, Link } from 'react-router-dom';
import '../App.css';
import './Zackat.css';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { REACT_APP_API_ENDPOINT } = process.env;
  const navigate = useNavigate();
  // Call the server API to check if the given email ID already exists
  const checkAccountExists = (callback) => {
    fetch(`${REACT_APP_API_ENDPOINT}/check-account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((r) => r.json())
      .then((r) => {
        callback(r?.userExists);
      });
  };
  // Log in a user using email and password
  const logIn = () => {
    fetch(`${REACT_APP_API_ENDPOINT}/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.message === 'success') {
          localStorage.setItem('user', JSON.stringify({
            _id: r._id,
            loggedIn: true,
            email: r.email,
            token: r.token,
            organizationid: r.organizationid,
          }));
          props.setLoggedIn(true);
          props.setEmail(email);
          // console.log('set auth orgs', r);
          if (r.lastReset) {
            const reset = parseISO(r.lastReset, 'MM/dd/yy hh:mm');
            const d = new Date();
            if (((d - reset) / 86400000) > 90) {
              alert(`Your password has expired, please update your password! ${(d - reset) / 86400000}`);
            }
          }
          /*
          localStorage.setItem('organization', JSON.stringify({ _id: r.organizationid, name: r.organization }));
          */
          if (r.landingPage) {
            navigate(`/${r.landingPage}`);
          } else {
            navigate('/associates');
          }
        } else {
          window.alert('Wrong email or password');
        }
      });
  };
  const onButtonClick = () => {
    // Set initial error values to empty
    setEmailError('');
    setPasswordError('');

    // Check if the user has entered both fields correctly
    if (email === '') {
      setEmailError('Please enter your email');
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email');
      return;
    }
    if (password === '') {
      setPasswordError('Please enter a password');
      return;
    }

    if (password.length < 7) {
      setPasswordError('The password must be 8 characters or longer');
      return;
    }

    // Authentication calls will be made here...
    // Check if email has an account associated with it
    checkAccountExists((accountExists) => {
      // If yes, log in
      if (accountExists) logIn();
      // Else, ask user if they want to create a new account and if yes, then log in
      else if (
        alert(
          `An account does not exist with this email address: ${email
          }. !`,
        )
      ) {
        navigate('./login');
        //        console.error('No you may not create your own login');
        // logIn()
      }
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      logIn();
    }
  };

  return (
    <div className="flex mainContainer box zackat-div">
      <div className="titleContainer">
        <img src="https://monaco.zackat.com/logo.png" width="40px" height="59px" />
        <div>Login</div>
      </div>
      <br />
      <div
        className="flex inputContainer"
        style={
        {
          border: '2px solid black',
          borderRadius: '25px',
          padding: '10px',
          width: 'auto',
        }
      }
      >
        <div>
          <input
            value={email}
            placeholder="Enter your email here"
            onChange={(ev) => setEmail(ev.target.value)}
            className="inputBox"
          />
          <label className="errorLabel">{emailError}</label>
        </div>
        <br />
        <div className="inputContainer">
          <input
            value={password}
            type="password"
            placeholder="Enter your password here"
            onChange={(ev) => setPassword(ev.target.value)}
            onKeyDown={handleKeyDown}
            className="inputBox"
          />
          <label className="errorLabel">{passwordError}</label>
        </div>
        <br />
        <div className="inputContainer">
          <input className="inputButton" type="button" onClick={onButtonClick} value="Log in ↵" />
        </div>
        <Link to="/forgot">Forgot Password </Link>
      </div>
    </div>
  );
};
export default Login;
