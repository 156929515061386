/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client';
import { registerLicense } from '@syncfusion/ej2-base';
import './index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';

const { REACT_APP_SYNCFUSION_LICENSE_KY } = process.env;
registerLicense(`${REACT_APP_SYNCFUSION_LICENSE_KY}`);
/*
ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <App />
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
*/

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ContextProvider>
    <App tab="home" />
  </ContextProvider>,
);
