/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react';

const Forgot = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const forgotClick = (args) => {
    console.log('forgotClick', args, email);
    setEmailError('');
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email');
      return;
    }
    const u = {};
    u.email = email;
    console.log(u);
    fetch(`${REACT_APP_API_ENDPOINT}/forgot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(u),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === 'reset requested') {
          alert('Your email reset has been received, if an active account is provisioned on the system, a temporary password will be sent to the email on file.');
        } else {
          alert('Could not locate email on the system!');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className="mainContainer box">
      <div className="titleContainer">
        <div>Forgot Password</div>
      </div>
      <br />
      <div style={
                {
                  border: '2px solid black',
                  borderRadius: '25px',
                  padding: '10px',
                }
}
      >

        <div className="inputContainer">
          <input
            value={email}
            placeholder="Enter your email here"
            onChange={(ev) => setEmail(ev.target.value)}
            className="inputBox"
          />
          <label className="errorLabel">{emailError}</label>
          <br />
          <div className="inputContainer">
            <input className="inputButton" type="button" onClick={forgotClick} value="Request reset ↵" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Forgot;
