/* eslint-disable consistent-return */
import React from 'react';

import { useNavigate } from 'react-router-dom';

function Methods(service, method, param) {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const navigate = useNavigate();
  const { REACT_APP_API_ENDPOINT } = process.env;

  const usr = JSON.parse(localStorage.getItem('user'));
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  if (selectedOrg && usr) {
    usr.organizationid = selectedOrg.id;
  }
  if (param.length > 0) {
    if (service === 'notifications') {
      usr.limit = param;
    } else {
      usr.associateid = param;
    }
  }
  if (!usr || usr.loggedIn === false) {
    // Redirect
    navigate('/login');
    return;
  }

  React.useEffect(() => {
    setLoading(true);
    if (method !== 'GET') {
      fetch(`${REACT_APP_API_ENDPOINT}/${method}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify({ usr }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setData(responseJson);
          setLoading(false);
        })
        .catch((error) => {
          console.log('Methods error', error);
          setLoading(true);
        });
    } else if (method === 'GET') {
      if (service === 'events' && param.length > 0) {
        usr.associateid = param;
      }
      if (service === 'reports' && param.length > 0) {
        usr.report = param;
      }
      const query = Object.entries(usr)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
      const url = `${REACT_APP_API_ENDPOINT}/${service}?${query}`;
      fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWTToken ${usr.token}`,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (typeof responseJson === 'string' || responseJson instanceof String) {
            const obj = JSON.parse(responseJson);
            if (obj && obj.response && obj.response.includes('failure')) {
              console.log('Methods', service, responseJson);
              navigate('/login');
              return;
            }
          }
          setData(responseJson);
          setLoading(false);
        })
        .catch((error) => {
          console.log('Methods GET error', error);
          localStorage.setItem('user', null);
          navigate('/login');
        });
    }
  }, []);
  return { loading, data };
}
export { Methods };
