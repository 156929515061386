/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get('resetToken');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { REACT_APP_API_ENDPOINT } = process.env;
  const navigate = useNavigate();

  // const history = useHistory();

  const validateForm = () => {
    setEmailError('');
    setPasswordError('');
    if (email.length > 0) {
      console.log(password, confirm, email);
      if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        setEmailError('Please enter a valid email');
        return false;
      }

      if (password.length < 7) {
        setPasswordError('The password must be 8 characters or longer');
        return false;
      }
      if (password !== confirm) {
        setPasswordError('The new password must match the confirm password');
        return false;
      }
      const u = {};
      u.email = email;
      u.resetToken = resetToken;
      u.newPassword = password;
      console.log(u);
      fetch(`${REACT_APP_API_ENDPOINT}/resetpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(u),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message === 'password reset') {
            alert('Your password has been reset');
            navigate('../login');
          } else {
            alert('Could not reset your password, please contact your provider!');
          }
        })
        .catch((error) => {
          console.error(error);
        });
      return true;
    }
    return false;
  };
  const handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();
  };

  return (
    <div className="mainContainer box">
      <div className="titleContainer">
        <div>Reset Password</div>
      </div>
      <br />
      <div className="password-reset">
        <div className="password-reset-form-container">
          <div className="inputContainer">
            <form>
              <div className="inputContainer">
                <label className="errorLabel">{emailError}</label>
                <input
                  type="email"
                  aria-label="Username"
                  placeholder="Email"
                  value={email}
                  className="inputBox"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <br />
              <div className="inputContainer">
                <label className="errorLabel">{passwordError}</label>
                <input
                  type="password"
                  aria-label="New Password"
                  placeholder="New password"
                  className="inputBox"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <br />
                <input
                  type="password"
                  aria-label="Confirm"
                  placeholder="Confirm password"
                  value={confirm}
                  className="inputBox"
                  onChange={(e) => setConfirm(e.target.value)}
                />
              </div>
              <br /><input
                value={resetToken}
                type="hidden"
              />
              <div className="form-actions">
                <input className="inputButton p-1" type="button" onClick={validateForm} value="Submit" />
                <input className="inputButton" type="button" onClick={handleSubmit} value="Reset" />
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
