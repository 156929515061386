/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Sort,
  Search,
  Filter,
  Page,
  Resize,
  Edit,
  Inject, Toolbar,
  ContextMenu,
  ForeignKey,
} from '@syncfusion/ej2-react-grids';
import { useNavigate } from 'react-router-dom';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';

import { contextMenuItems, organizationsGrid } from '../data/dummy';
import { Header } from '../components';
import { Methods } from './Methods';
import './Zackat.css';

const Organizations = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const orgData = [];
  let grid = useRef(null);
  const valuerules = { required: true };
  const { loading, data } = Methods('organizations', 'GET', '');
  const navigate = useNavigate();

  const clickHandler = (args) => {
    if (grid && args.item.properties.text === 'Recordings') {
      navigate('../recordings');
    }
  };
  const actionComplete = (args) => {
    const editedData = args.data;

    const usr = JSON.parse(localStorage.getItem('user'));
    const activeOrg = JSON.parse(localStorage.getItem('organization'));
    const cols = grid.columns;
    for (const col of cols) {
      if (col.headerText === 'Image') {
        col.allowEditing = false;
      }
    }
    /** Set initial Focus */
    if (args.requestType === 'beginEdit') {
      args.form.elements.namedItem('title').focus();
    } else if (args.form && args.requestType === 'add') {
      args.form.elements.namedItem('title').focus();
    } else if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data) {
        // The default edit operation is cancelled
        if (!editedData.organization && activeOrg) {
          editedData.parent._id = activeOrg.id;
          editedData.parent.name = activeOrg.name;
        } else {
          orgData.forEach((element) => {
            if (element.value == editedData.organization) {
              editedData.parent._id = element.id;
              editedData.parent.name = element.value;
            }
          });
        }
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/organizations`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),

        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            grid.endEdit();
            args.cancel = true;
            if (responseJson && responseJson._id) {
              args.data._id = responseJson._id;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      if (args.data) {
        if (activeOrg) {
          editedData.parent._id = activeOrg.id;
          editedData.parent.name = activeOrg.name;
        } else {
          orgData.forEach((element) => {
            if (element.value == editedData.organization) {
              editedData.parent._id = element.id;
              editedData.parent.name = element.value;
            }
          });
        }
        console.log('UPDATE EDITEDDATA', editedData);

        // The default edit operation is cancelled
        args.cancel = true;
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/organizations`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson);
            // The added/edited data will be saved in the Grid
            grid.endEdit();
            args.cancel = true;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (args.requestType === 'delete') {
      let entry = ' this entry?';
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // The default edit operation is cancelled
        args.cancel = true;
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/organizations`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson);
            // The added/edited data will be saved in the Grid
            grid.endEdit();
            args.cancel = true;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const actionBegin = (args) => {
    const cols = grid.columns;
    for (const col of cols) {
      if (col.headerText === 'Image') {
        col.allowEditing = false;
      }
      if (args.requestType == 'beginEdit') {
        if (col.headerText == 'Organization' || col.headerText == 'Address' || col.headerText == 'City' || col.headerText == 'State' || col.headerText == 'Zip') {
          col.visible = true;
          args.rowData.organization = args.rowData.parent.name;
        }
        if (col.headerText == 'Parent') {
          col.visible = false;
          col.allowEditing = false;
        }
      } else if (args.requestType == 'add') {
        if (col.headerText == 'Organization' || col.headerText == 'Address' || col.headerText == 'City' || col.headerText == 'State' || col.headerText == 'Zip') {
          col.visible = true;
        } if (col.headerText == 'Parent') {
          col.visible = false;
        }
      } else {
        if (col.headerText == 'Organization' || col.headerText == 'Address' || col.headerText == 'City' || col.headerText == 'State' || col.headerText == 'Zip') {
          col.visible = true;
        }
        if (col.headerText == 'Parent') {
          col.visible = false;
        }
      }
    }
  };
  if (loading) {
    return <p>Loading... </p>;
  }
  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  if (data.role.organizations.permissions.includes('Create')) {
    crudCreate = true;
  }
  if (data.role.organizations.permissions.includes('Update')) {
    crudUpdate = true;
  }
  if (data.role.organizations.permissions.includes('Delete')) {
    crudDelete = true;
  }
  if (!data.role.organizations.permissions.includes('Read')) {
    return <p>Not authorized...</p>;
  }
  const recordings = { text: 'Recordings', tooltipText: 'Canned Recordings', prefixIcon: 'e-expand', id: 'recordings', disabled: !crudCreate };
  const toolbarOptions = ['Search', 'Add', 'Edit', 'Delete', recordings];

  const editing = {
    allowEditing: crudUpdate,
    allowAdding: crudCreate,
    allowDeleting: crudDelete,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    mode: 'Dialog',
  };
  function onFileUpload(args) {
    // add addition data as key-value pair.
    console.log('onFileUpload', args);
    args.customFormData = [{ organizationid: data.user.organizationid }];
  }
  function completeUpload(args) {
    console.log('completeUpload', args);
    // window.location.reload()
  }
  let uploadObj;
  const path = {
    removeUrl: `${REACT_APP_API_ENDPOINT}/organizationsremove`,
    saveUrl: `${REACT_APP_API_ENDPOINT}/organizationsupload`,
  };
  const buttons = { browse: 'Choose File', clear: 'Clear All', upload: 'Upload All' };

  const FilterOptions = {
    type: 'Menu',
  };
  data.organization.forEach((org) => {
    orgData.push({ id: org._id, value: org.title });
  });

  data.organization.orgData = orgData;
  const orgParams = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: orgData,
      fields: { value: 'value', id: 'id' },
      query: new Query(),
    },
  };
  const currentThemeColor = localStorage.getItem('colorMode');
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Organizations" />
      <div style={{
        backgroundColor: currentThemeColor,
        textAlign: 'right',
        marginTop: '-40px',
      }}
      >

        <div id="droparea">{crudCreate ? 'IMPORT' : ''}
          {crudCreate === true && (
            <UploaderComponent
              id="fileupload"
              type="file"
              ref={(uplaod) => { uploadObj = uplaod; }}
              asyncSettings={path}
              buttons={buttons}
              multiple={false}
              actionComplete={completeUpload}
              autoUpload={false}
              uploading={onFileUpload}
              allowedExtensions=".csv"
            />
          )}
        </div>
      </div>
      <GridComponent
        id="gridcomp"
        dataSource={data.organization}
        allowPaging
        allowSorting
        contextMenuItems={contextMenuItems}
        editSettings={editing}
        toolbar={toolbarOptions}
        toolbarClick={clickHandler}
        allowResizing
        allowFiltering
        filterSettings={FilterOptions}
        actionComplete={actionComplete}
        actionBegin={actionBegin}
        allowTextWrap
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          <ColumnDirective field="title" headerText="Customer Name" width="150" validationRules={valuerules} textAlign="Left" />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {organizationsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          <ColumnDirective
            field="parent._id"
            headerText="Organization"
            width="150"
            visible={false}
            editType="dropdownedit"
            validationRules={valuerules}
            foreignKeyField="id"
            foreignKeyValue="value"
            dataSource={orgParams.params.dataSource}
          />
        </ColumnsDirective>
        <Inject services={[Search, Page, Edit, Toolbar, Filter, Sort, ContextMenu, Resize, ForeignKey]} />
      </GridComponent>

    </div>
  );
};
export default Organizations;
