/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-kanban';
import { TextBoxComponent, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { addClass } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { inventoryGrid } from '../data/dummy';
import armband from '../data/watchsketch.png';
import { Header } from '../components';
import { Methods } from './Methods';
import './inventory.css';
import './Zackat.css';

const Inventory = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const { loading, data } = Methods('inventory', 'GET', '');
  if (loading) {
    return <p>Loading... </p>;
  }
  const usr = JSON.parse(localStorage.getItem('user'));
  const actionComplete = (args) => {
    /** Set initial Focus */
    if (args.requestType === 'cardCreated') {
      if (args.addedRecords && confirm('Would you really like to add this entry?')) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.addedRecords),

        })
          .then((response) => response.json())
          .then((responseJson) => {
            args.cancel = true;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (args.requestType === 'cardChanged') {
      if (args) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.changedRecords),

        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            args.cancel = true;
          })
          .catch((error) => {
            console.error('error');
          });
      }
    } else if (args.requestType === 'cardRemoved') {
      if (args.deletedRecords) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/inventory`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.deletedRecords),

        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            args.cancel = true;
          })
          .catch((error) => {
            console.error('error');
          });
      }
    }
  };

  const actionBegin = (args) => {
    console.log('actionBegin', args);
  };

  function cardTemplate(props) {
    return (
      <div className="card-template">
        <div
          style={
        {
          border: '2px solid darkgrey',
        }
}
          className="e-card-content e-card-separator"
        >
          <table className="card-template-wrap">
            <tbody>
              <tr>
                <td className="CardHeader">Associate:</td>
                <td><img
                  className="e-card-avatar"
                  src={props.resultField && props.resultField[0] ? props.resultField[0].employeeImage : 'https://monaco.zackat.com/blank.png'}
                  width="50"
                  height="50"
                />{props.resultField && props.resultField[0] ? props.resultField[0].name : 'unassigned'}
                </td>
              </tr>
              <tr>
                <td className="CardHeader">Device:</td>
                <td>{props.device}</td>
              </tr>
              <tr>
                <td className="CardHeader">IMEI:</td>
                <td>{props.imei}</td>
              </tr>
              <tr>
                <td className="CardHeader">MSN:</td>
                <td>{props.msn}</td>
              </tr>
              <tr>
                <td className="CardHeader">Version:</td>
                <td>{props.version}
                </td>
              </tr>
              <tr>
                <td className="CardHeader">Battery:</td>
                <td style={{
                  color: props.battery <= '60' ? 'red' // you may use your preferred color hexadecimal instead (ex: #f00)
                    : 'green', // you may use your preferred color hexadecimal instead (#00f)
                }}
                >{props.battery}%
                </td>
              </tr>
              <tr>
                <td className="CardHeader">RSSI:</td>
                <td style={{
                  color: props.rssi <= '-120' ? 'red' // you may use your preferred color hexadecimal instead (ex: #f00)
                    : 'green', // you may use your preferred color hexadecimal instead (#00f)
                }}
                > {props.rssi}dBm
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  if (data.role.inventory && data.role.inventory.permissions.includes('Create')) {
    crudCreate = true;
  }
  if (data.role.inventory && data.role.inventory.permissions.includes('Update')) {
    crudUpdate = true;
  }
  if (data.role.inventory && data.role.inventory.permissions.includes('Delete')) {
    crudDelete = true;
  }
  if (data.role.inventory && !data.role.inventory.permissions.includes('Read')) {
    return <p>Not authorized...</p>;
  }
  const fields = [
    { text: 'Device', key: 'device', type: 'TextBox' },
    { key: 'status', type: 'DropDown' },
    { key: 'version', type: 'TextBox' },
    { key: 'rssi', type: 'TextArea' },
    { key: 'imei', type: 'TextBox' },
    { key: 'msn', type: 'TextBox' },
  ];

  function columnTemplate(props) {
    return (
      <div className="header-template-wrap">
        <div className={`header-icon e-icons ${props.keyField}`} />
        <div className="header-text">{props.headerText}</div>
      </div>
    );
  }

  function cardRendered(args) {
    const val = args.data.status;
    addClass([args.element], val);
  }
  function onFileUpload(args) {
    // add addition data as key-value pair.
    args.customFormData = [{ organizationid: data.user.organizationid }];
  }

  let uploadObj;
  const path = {
    removeUrl: `${REACT_APP_API_ENDPOINT}/inventoryremove`,
    saveUrl: `${REACT_APP_API_ENDPOINT}/inventoryupload`,
  };

  let kanbanObj;
  let priorityObj;
  let textBoxObj;
  function searchClick(e) {
    const searchValue = e.value;
    let searchQuery = new Query();
    if (searchValue !== '') {
      searchQuery = new Query().search(searchValue, ['device', 'imei', 'msn'], 'contains', true);
    }
    kanbanObj.query = searchQuery;
  }

  function reset() {
    textBoxObj.value = '';
    kanbanObj.query = new Query();
  }

  function change(args) {
    let filterQuery = new Query();
    if (args.value !== 'None') {
      if (args.element.id === 'version') {
        filterQuery = new Query().where('version', 'equal', args.value);
      }
    }
    kanbanObj.query = filterQuery;
  }
  const priorityData = ['None', '1.0', '1.1', '1.2', '1.3'];

  function completeUpload(args) {
    console.log('completeUpload', args);
  }
  const buttons = { browse: 'Choose File', clear: 'Clear All', upload: 'Upload All' };
  const currentThemeColor = localStorage.getItem('colorMode');

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <img
        className="w-20 h-20"
        src={armband}
        alt="arm band"
      />
      <Header title="Inventory" />
      <div style={{
        backgroundColor: currentThemeColor,
        textAlign: 'right',
        marginTop: '-40px',
      }}
      >
        <div id="droparea">{crudCreate ? 'IMPORT' : ''}
          {crudCreate === true && (
          <UploaderComponent
            id="fileupload"
            type="file"
            ref={(uplaod) => { uploadObj = uplaod; }}
            asyncSettings={path}
            buttons={buttons}
            multiple={false}
            actionComplete={completeUpload}
            autoUpload={false}
            uploading={onFileUpload}
            allowedExtensions=".csv"
          />
          )}
        </div>
      </div>
      <div className="control-wrapper">
        <div>
          <div style={{ border: '1pxtip solid black', width: '100%' }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: '200px' }}>
                    Search:<TextBoxComponent id="search" ref={(kanban) => { textBoxObj = kanban; }} showClearButton placeholder="Enter search text" input={searchClick} />
                  </td>
                  <td style={{ width: '200px' }}>
                    <ButtonComponent id="reset" className="e-btn" onClick={reset}>Reset</ButtonComponent>
                  </td>
                  <td style={{ width: '200px' }}>
                    Filter:<DropDownListComponent id="verion" ref={(kanbant) => { priorityObj = kanbant; }} dataSource={priorityData} change={change} placeholder="Select a version" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <KanbanComponent
          cssClass="kanban-overview"
          id="inventory"
          keyField="status"
          dataSource={data.inventory}
          actionComplete={actionComplete}
          actionBegin={actionBegin}
          dialogSettings={{ fields, className: 'e-dialog-delete' }}
          allowDragAndDrop
          enablePersistence={false}
          ref={(kanban) => { kanbanObj = kanban; }}
          cardSettings={{
            headerField: 'device',
            contentField: 'imei',
            template: cardTemplate,
          }}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {inventoryGrid.map((item, index) => (
              <ColumnDirective
                key={index}
                {...item}
                template={columnTemplate}
                showAddButton
              />
            ))}
          </ColumnsDirective>
        </KanbanComponent>
      </div>
    </div>
  );
};
export default Inventory;
