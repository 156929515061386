/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Zackat.css';

const Home = (props) => {
  const { loggedIn, email } = props;
  const navigate = useNavigate();

  const onButtonClick = () => {
    if (loggedIn) {
      localStorage.removeItem('user');
      props.setLoggedIn(false);
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="mainContainer">
      <div className="titleContainer">
        <div>Welcome!</div>
      </div>
      <div>This is the home page.</div>
      <div className="buttonContainer">
        <input
          className="inputButton"
          type="button"
          onClick={onButtonClick}
          onKeyDown={onButtonClick}
          value={loggedIn ? 'Log out ↵' : 'Log in ↵'}
        />
        {loggedIn ? <div className="kern">Your email address is {email}</div> : <div />}
      </div>
    </div>
  );
};

export default Home;
