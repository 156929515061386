/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar, Edit, Sort, Filter, ForeignKey,
} from '@syncfusion/ej2-react-grids';
import { Query } from '@syncfusion/ej2-data';
import { useStateContext } from '../contexts/ContextProvider';
import orgpng from '../data/organizations_on orange.png';
import { alertsGrid } from '../data/dummy';
import { Header } from '../components';
import { Methods } from './Methods';

const Alerts = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const toolbarOptions = ['Delete', 'Add', 'Edit', 'Search', 'Update', 'Cancel'];
  const { activeMenu } = useStateContext();
  const selectionSettings = { mode: 'Row', type: 'Single' };
  const valuerules = { required: true };
  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;

  let grid;
  let myScreenSize = 'auto';
  const {
    screenSize,
    setScreenSize,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (activeMenu === false) {
    myScreenSize = 'auto';
  } else {
    const s = (screenSize - 450).toString();
    myScreenSize = `${s}px`;
  }
  const gridOrgHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={orgpng}
        alt="organization"
      />
      <pre>Organization</pre>
    </div>
  );
  const usr = JSON.parse(localStorage.getItem('user'));

  const { loading, data } = Methods('alerts', 'GET', '');
  const orgData = [];
  const actionComplete = (args) => {
    /** Set initial Focus */
    if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        orgData.forEach((element) => {
          if (element.id === args.data.organizationid) {
            args.data.organizationid = element.id;
            args.data.organization = element.value;
          }
        });

        fetch(`${REACT_APP_API_ENDPOINT}/alerts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            grid.endEdit();
            args.cancel = true;
            data.alerts[0]._id = responseJson._id;
          })
          .catch((error) => {
            // alert('An error has occured during Insert!');
            console.log(error);
          });
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      // Here you can send the updated data to your server using AJAX call
      orgData.forEach((element) => {
        if (element.id === args.data.organizationid) {
          args.data.organizationid = element.id;
          args.data.organization = element.value;
        }
      });
      fetch(`${REACT_APP_API_ENDPOINT}/alerts`, {
        method: 'UPDATE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify(args.data),
      })
        .then((response) => response.json())
        .then(() => {
          // The added/edited data will be saved in the Grid
          // The default edit operation is cancelled
          args.cancel = true;
          grid.endEdit();
        })
        .catch((error) => {
          // alert('An error has occured during Update!');
          console.log(error);
        });
    } else if (args.requestType === 'delete') {
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/alerts`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then(() => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
            args.cancel = true;
            grid.endEdit();
          })
          .catch((error) => {
            // alert('An error has occured during Delete!');
            console.log(error);
          });
      }
    }
  };
  if (loading) {
    return <p>Loading... </p>;
  }
  if (data.role && data.role.alerts) {
    if (data.role.alerts.permissions.includes('Create')) {
      crudCreate = true;
    }
    if (data.role.alerts.permissions.includes('Update')) {
      crudUpdate = true;
    }
    if (data.role.alerts.permissions.includes('Delete')) {
      crudDelete = true;
    }
    if (!data.role.alerts.permissions.includes('Read')) {
      return <p>Not authorized...</p>;
    }
  }
  const editing = {
    allowEditing: crudUpdate,
    allowAdding: crudCreate,
    allowDeleting: crudDelete,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    allowEditOnDblClick: false,
    mode: 'Dialog',
  };
  if (data.organization) {
    data.organization.forEach((org) => {
      orgData.push({ id: org._id, value: org.title });
    });
  }
  const organizations = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: data.organization,
      fields: { value: '_id', text: 'title' },
      query: new Query(),
    },
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Alert Thresholds" />
      <GridComponent
        dataSource={data.alerts}
        width={myScreenSize}
        allowPaging
        allowSorting
        pageSettings={{ pageSize: 20 }}
        editSettings={editing}
        toolbar={toolbarOptions}
        actionComplete={actionComplete}
        selectionSettings={selectionSettings}
        allowTextWrap
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {alertsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          <ColumnDirective
            field="organizationid"
            headerText="Organization"
            width="150"
            textAlign="Left"
            editType="dropdownedit"
            headerTemplate={gridOrgHeader}
            edit={organizations}
            visible
            foreignKeyField="_id"
            foreignKeyValue="title"
            validationRules={valuerules}
            dataSource={organizations.params.dataSource}
          />
        </ColumnsDirective>
        <Inject services={[Search, Page, Toolbar, Edit, Sort, Filter, ForeignKey]} />
      </GridComponent>
    </div>
  );
};
export default Alerts;
