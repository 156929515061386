/* eslint-disable react/button-has-type */
import * as React from 'react';
import { useState } from 'react';
import './card.component.css';
import { useSearchParams, Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Methods } from '../pages/Methods';
import temperature from '../data/temperature.png';
import oxygen from '../data/oxygen.png';
import hydration from '../data/hydration.png';
import bloodpressure from '../data/bloodpressure.png';
import fatigue from '../data/fatigue.png';
import activity from '../data/activity.png';
import heartrate from '../data/heartrate.png';

const Flip = () => {
  const [isFlipped1, setIsFlipped1] = useState(true);
  const [class1, setClass1] = useState('e-card e-business e-flip');
  const flip1 = () => {
    setIsFlipped1(!isFlipped1);
    setClass1(isFlipped1 ? 'e-card e-business e-flip e-flipped' : 'e-card e-business e-flip');
  };
  const [searchParams] = useSearchParams();
  const associateid = searchParams.get('id');
  const { loading, data } = Methods('associates', 'GET', associateid);
  if (loading) {
    return <p>Loading... </p>;
  }
  return (
    <div className="control-pane">
      <div className="control-section card-control-section flip_card_layout ">
        <div className="e-card-resize-container">
          <div className="row">
            <div className="row card-layout">
              <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 zackat-div">
                <div className={class1} id="card_flip" onClick={flip1} title="Click to flip the Card">
                  <div className="e-card-front e-front">
                    <div className="e-card-header e-card-right" style={{ justifyContent: 'flex-end' }}>
                      <div><img src={data.associates[0].employeeImage} width="40px" height="40px" /></div>
                    </div>
                    <div className="e-card-header e-card-right" style={{ textAlign: 'right' }}>
                      <div className="e-card-header-caption">
                        <div className="e-card-header-title">{data.associates[0].Title}</div>
                      </div>
                    </div>
                    <div className="e-card-header e-card-left" style={{ textAlign: 'left' }}>
                      <div className="e-card-header-caption">
                        <div className="e-card-header-title">{data.associates[0].name}</div>
                        <div className="e-card-header-title">{data.associates[0].address}</div>
                        <div className="e-card-header-title">{data.associates[0].address1}</div>
                        <div className="e-card-header-title">{data.associates[0].city}&nbsp;{data.associates[0].state},&nbsp;{data.associates[0].zip}</div>
                        <div className="e-card-sub-title">{data.associates[0].jobtask}</div>
                      </div>
                    </div>
                    <div className="e-card-separator e-card-left" />
                    <div className="e-card-content e-card-left" style={{ textAlign: 'left' }}>
                      <table>
                        <tbody>
                          <tr>
                            <td>{data.associates[0].organization}</td>
                          </tr>
                          <tr>
                            <td>{data.associates[0].device}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="e-card-header e-back">
                    <div className="e-card-header-caption">
                      <div className="e-card-header-title">Vitals</div>
                      <div className="e-card-sub-title">
                        <div className="flex items-center gap-2">
                          <img
                            className="w-10  h-12 pt-2"
                            src={temperature}
                            alt="temperature"
                          />
                          <Link to={`/events?id=${associateid}`}>
                            <pre>{data.associates[0].temperature.reading ? `${data.associates[0].temperature.reading} °F\n${format(parseISO(data.associates[0].temperature.timestamp), 'MM/dd/yy hh:mm')}` : ''}</pre>
                          </Link>
                        </div>
                        <div className="flex items-center gap-2">
                          <img
                            className="w-10  h-12 pt-2"
                            src={oxygen}
                            alt="oxygen"
                          />
                          <Link to={`/events?id=${associateid}`}>
                            <pre>{data.associates[0].oxygen.reading ? `${data.associates[0].oxygen.reading} %\n${format(parseISO(data.associates[0].oxygen.timestamp), 'MM/dd/yy hh:mm')}` : ''}</pre>
                          </Link>
                        </div>
                        <div className="flex items-center gap-2">
                          <img
                            className="w-10  h-12 pt-2"
                            src={hydration}
                            alt="hydration"
                          />
                          <Link to={`/events?id=${associateid}`}>
                            <pre>{data.associates[0].hydration.reading ? `${data.associates[0].hydration.reading} %\n${format(parseISO(data.associates[0].hydration.timestamp), 'MM/dd/yy hh:mm')}` : ''}</pre>
                          </Link>
                        </div>
                        <div className="flex items-center gap-2">
                          <img
                            className="w-10  h-12 pt-2"
                            src={fatigue}
                            alt="fatigue"
                          />
                          <Link to={`/events?id=${associateid}`}>
                            <pre>{data.associates[0].fatigue.reading ? `${data.associates[0].fatigue.reading}\n${format(parseISO(data.associates[0].fatigue.timestamp), 'MM/dd/yy hh:mm')}` : ''}</pre>
                          </Link>
                        </div>
                        <div className="flex items-center gap-2">
                          <img
                            className="w-10  h-12 pt-2"
                            src={heartrate}
                            alt="heartrate"
                          />
                          <Link to={`/events?id=${associateid}`}>
                            <pre>{data.associates[0].heartrate.reading ? `${data.associates[0].heartrate.reading} bpm\n${format(parseISO(data.associates[0].heartrate.timestamp), 'MM/dd/yy hh:mm')}` : ''}</pre>
                          </Link>
                        </div>
                        <div className="flex items-center gap-2">
                          <img
                            className="w-10 h-12 pt-2"
                            src={bloodpressure}
                            alt="bloodpressure"
                          />
                          <Link to={`/events?id=${associateid}`}>
                            <pre>{data.associates[0].bloodpressure.reading ? `${data.associates[0].bloodpressure.reading} sys/dia\n${format(parseISO(data.associates[0].bloodpressure.timestamp), 'MM/dd/yy hh:mm')}` : ''}</pre>
                          </Link>
                        </div>
                        <div className="flex items-center gap-">
                          <img
                            className="w-10 h-12 pt-2"
                            src={activity}
                            alt="activity"
                          />
                          <Link to={`/events?id=${associateid}`}>
                            <pre>{data.associates[0].activity.reading ? `${data.associates[0].activity.reading} steps\n${format(parseISO(data.associates[0].activity.timestamp), 'MM/dd/yy hh:mm')}` : ''}</pre>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Flip;
